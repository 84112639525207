/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.center {
  margin-left: auto;
  margin-right: auto;
}

.table-container {
  @apply w-full px-8 my-8 sm:px-0 sm:w-5/6 md:w-2/3 max-w-5xl
}

.text-container {
  @apply w-full px-8 mt-8 sm:px-0 sm:w-5/6 md:w-2/3 max-w-5xl
}

.profile-container {
  @apply w-full px-8 mt-8 sm:px-0 sm:w-5/6 md:w-2/3 max-w-5xl
}

.nav-link{
  @apply transition border p-1 px-3 border-transparent duration-150 text-white hover:border-white
}

.link{
  @apply transition duration-100 text-cardinal hover:underline
}

.header {
  overflow: hidden;
  background-color: #8C1515;
  color: white;
  margin: -10px;
  padding: 0px 10px;
  text-align: center;
  box-shadow: 0 0 10px rgb(102, 102, 102);
  z-index: 10;
}

p {
  @apply font-sourcesans text-base md:text-lg
}

h1 {
  @apply font-sourcesans text-white text-2xl;
}

h2 {
  @apply font-sourcesans text-black font-bold text-2xl;
}

@font-face {
  font-family: 'SourceSans';
  font-weight: 400;
  src: url('./fonts/SourceSansPro/SourceSansPro-Regular.ttf')
}

/* width */
::-webkit-scrollbar {
  @apply w-4
}

/* Track */
::-webkit-scrollbar-track {
  background: #fdeeee;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cc4c5d;
  border-radius: 100vh;
  border: 3px solid #fdeeee;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-cardinal
}
